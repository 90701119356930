import React from 'react'
import { string } from 'prop-types'
import classnames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import ShrinkWrap from '@/component/Primitive/ShrinkWrap'
import SmartLink from '@/component/Primitive/SmartLink'
import Icon from '@/component/Primitive/Icon'

import styles from './ListItem.module.scss'

const ListItem = ({ title, url, fileType, fileSize, to, as }) => {
  const isDark = useDarkContext()
  return (
    <li className={classnames(styles.ListItem, isDark && styles.isDark)}>
      <SmartLink to={to} as={as} href={url}>
        <ShrinkWrap vAlign="middle">
          <ShrinkWrap.Item shrink>
            <Icon
              className={styles.DownloadIcon}
              type="download"
              width={40}
              a11yText="Download"
            />
          </ShrinkWrap.Item>
          <ShrinkWrap.Item>
            <Type as="h4" size="base">
              {title} {fileType && fileSize && `(${fileType}, ${fileSize})`}
            </Type>
          </ShrinkWrap.Item>
        </ShrinkWrap>
      </SmartLink>
    </li>
  )
}

ListItem.propTypes = {
  title: string,
  url: string,
  fileType: string,
  fileSize: string,
  to: string,
  as: string
}

export default ListItem
