import React, { useState } from 'react'
import styles from './WatchStep.module.scss'
import classNames from 'classnames'
import { func, object } from 'prop-types'

const IssueActionButton = ({ item, playedRef, handleClick }) => {
  const arrayLength = 20 // for confettiAnimation
  const myArray = Array.from({ length: arrayLength }, (_, index) => index + 1)
  const [confettiAnimating, setConfettiAnimating] = useState(false)
  const [icon, setIcon] = useState(null)

  const [isClicked, setIsClicked] = useState(false)
  const onClick = (item) => {
    handleClick(item.type, playedRef.current, item.id)

    setIsClicked(true)
    setConfettiAnimating(true)
    setIcon(item.emoji)
    setTimeout(() => {
      setIsClicked(false)
      setConfettiAnimating(false)
      setIcon(null)
    }, 1000)
  }

  return (
    <div className={styles.IssueActionButtonContainer}>
      <button
        className={classNames(
          styles.IssueActionButton,
          isClicked && styles.IssueActionActiveButton
        )}
        onClick={() => onClick(item)}
      >
        <span className={styles.IssueLabelIcon}>{item.emoji}</span>
        <span className={styles.IssueActionButtonText}>{item.issue}</span>
      </button>
      <div
        className={classNames(
          styles.ConfettiWrapper,
          confettiAnimating && styles.Show
        )}
      >
        {myArray.map((item, index) => (
          <div
            className={classNames(
              styles.ConfettiItem,
              styles[`Confetti-${item}`]
            )}
            key={index}
          >
            <span role="img" aria-label="">
              {icon}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

IssueActionButton.propTypes = {
  item: object,
  playedRef: object,
  handleClick: func
}

export default IssueActionButton
