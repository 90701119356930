import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LoadingSpinner from '@/component/Primitive/Spinner'
import FormBuilderQuestion from './FormBuilderQuestion'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Type from '@/component/Primitive/Type'
import {
  isError,
  showNotification,
  defaultServerErrorMessage,
  defaultValidationErrorMessage
} from './lib/form-errors'
import { useDarkContext } from '@/component/Context/DarkContext'

import styles from './FormBuilder.module.scss'

const FormBuilder = (props) => {
  const {
    form,
    successMessage,
    validationError,
    serverError,
    handleOnChange,
    handleOnSubmit,
    waiting,
    downloadUrl,
    downloadButtonText
  } = props
  const { questions, title } = form || {}
  const isDark = useDarkContext()
  return (
    <div className={styles.Form}>
      <Type as="h4" size="display1" className={styles.Title}>
        {title || ''}
      </Type>
      {isError(serverError) && showNotification(defaultServerErrorMessage)}
      {isError(validationError) &&
        showNotification(defaultValidationErrorMessage)}
      {successMessage && (
        <>
          <Type color={isDark && 'white'} as="p">
            {successMessage}
          </Type>
          {downloadUrl && (
            <ButtonStandard href={downloadUrl}>
              <Type size="base-large">{downloadButtonText || 'Download'}</Type>
            </ButtonStandard>
          )}
        </>
      )}
      {!successMessage && (
        <form
          onSubmit={handleOnSubmit}
          className={classNames(styles.Questions, isDark && styles.isDark)}
        >
          {questions &&
            questions.map((question, i) => (
              <FormBuilderQuestion
                key={`form-builder-${i}`}
                question={question}
                error={validationError}
                handleOnChange={handleOnChange}
                id={i + 1}
              />
            ))}
          {waiting ? (
            <LoadingSpinner />
          ) : (
            <ButtonStandard
              type="submit"
              value="Submit"
              onClick={handleOnSubmit}
              className={styles.SubmitButton}
            >
              <Type size="base-large">Submit</Type>
            </ButtonStandard>
          )}
        </form>
      )}
    </div>
  )
}

FormBuilder.propTypes = {
  handleOnSubmit: PropTypes.func,
  handleOnChange: PropTypes.func.isRequired,
  waiting: PropTypes.bool,
  successMessage: PropTypes.string,
  validationError: PropTypes.object,
  serverError: PropTypes.object,
  form: PropTypes.object,
  downloadUrl: PropTypes.string,
  downloadButtonText: PropTypes.string
}

export default FormBuilder
